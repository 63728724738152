import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './service/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule'
  },
  {
    path: 'profile',
    loadChildren: './pages/profile/profile.module#ProfilePageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'jobs',
    loadChildren: './pages/jobs/jobs.module#JobsPageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'job-detail/:id',
    loadChildren: './pages/job-detail/job-detail.module#JobDetailPageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'request-history',
    loadChildren: './pages/request-history/request-history.module#RequestHistoryModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'new-request/:year/:month/:day',
    loadChildren: './pages/new-request/new-request.module#NewRequestModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'new-request',
    loadChildren: './pages/new-request/new-request.module#NewRequestModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'pending-requests',
    loadChildren: './pages/pending-requests/pending-requests.module#PendingRequestsModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'request-single/:id',
    loadChildren: './pages/request-screen/request-screen.module#RequestScreenModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-calendar',
    loadChildren: './pages/my-calendar/my-calendar.module#MyCalendarModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'company-calendar',
    loadChildren: './pages/company-calendar/company-calendar.module#CompanyCalendarModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'company-calendar/:departmentId',
    loadChildren: './pages/company-calendar/company-calendar.module#CompanyCalendarModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'pending-request-box',
    loadChildren: './pages/pending-request-box/pending-request-box.module#PendingRequestBoxModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'update/:platform/:url',
    loadChildren: './pages/updater/updater.module#UpdaterPageModule'
  },
  {
    path: 'notifications',
    loadChildren: './pages/notification/notification.module#NotificationPageModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'notification-single/:id',
    loadChildren: './pages/notification-single/notification-single.module#NotificationSinglePageModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'posts',
    loadChildren: './pages/posts/posts.module#PostsPageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'post-single/:id',
    loadChildren: './pages/post-single/post-single.module#PostSinglePageModule',
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
