import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root',
})

export class AuthGuardService implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const accessToken = localStorage.getItem('access_token');

        if (accessToken == null) {
            this.router.navigateByUrl('/login');
            return false;
        }

        return true;
    }
}
