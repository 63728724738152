import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, EMPTY, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    url: string = environment.SERVER_URL;

    constructor(private http: HttpClient, private router: Router) {
    }

    login(username: string, password: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        };

        const params = JSON.stringify({username, password});

        return this.http.post(this.url + 'login_check', params, httpOptions).pipe(
            switchMap((data: any) => this.userData(data.token).pipe(
                map((user: any) => {
                    if (user.user) {
                        localStorage.setItem('userId', user.user.id);
                        localStorage.setItem('employee_id', user.user.employee_id);
                        localStorage.setItem('access_token', data.token);
                        localStorage.setItem('refresh_token', data.refresh_token);
                    }
                })
            ))
        );
    }
    getEmployeeId() {
        return localStorage.getItem('employee_id');
    }

    userData(inputToken): Observable<any> {
        let token;

        if (inputToken === null) {
            token = localStorage.getItem('access_token');
        } else {
            token = inputToken
        }

        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            })
        };

        return this.http.post(this.url + 'login', null, httpOptions).pipe(map(results => results));
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    refreshToken() {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
        };
        const refreshToken = localStorage.getItem('refresh_token');

        const params = JSON.stringify({refresh_token: refreshToken});

        return this.http.post<any>(this.url + 'refresh', params, httpOptions).pipe(tap((tokens: any) => {
            localStorage.setItem('access_token', tokens.token);
            localStorage.setItem('refresh_token', tokens.refresh_token);
        }));
    }
    logout() {
        this.clearUserData();
        this.router.navigateByUrl('/login');
    }

    clearUserData() {
        localStorage.setItem('access_token', null);
        localStorage.setItem('refresh_token', null);
        localStorage.setItem('userId', null);
        localStorage.clear();
    }

    request(method, url, body) {
        url = this.url + url;

        const options = {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        };
        const req = new HttpRequest(method, url, body, options);

        return this.http.request(req).pipe(map(data => {
            return data;
        }));
    }
}
