import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class NewRequestComponent implements OnInit {
  @Input() day;
  @Input() month;
  @Input() year;
  @Input() onClick = () => {};
  constructor(private router: Router) { }

  ngOnInit() {}

  click() {
    this.onClick();
    this.router.navigateByUrl('/new-request/'+this.year+'/'+this.month+'/'+this.day);
  }
}
