import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() title;
  @Input() description;
  @Input() date;
  @Input() dismiss = () => {};
  constructor(private router: Router) { }

  ngOnInit() {}

  click() {
    this.dismiss();
  }
}
