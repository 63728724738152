import {Component} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {AuthService} from './service/auth.service';
import { Plugins } from '@capacitor/core';
import {HttpResponse} from '@angular/common/http';
const { Device } = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public appPages = [
        {
            title: 'Holidator',
            url: null,
            icon: 'calendar',
            hidden: false,
            submenu:[
                {
                    title: 'New Request',
                    url: 'new-request',
                    icon: null
                },
                {
                    title: 'My requests history',
                    url: 'request-history',
                    icon: null
                },
                {
                    title: 'My calendar',
                    url: 'my-calendar',
                    icon: null
                },
                {
                    title: 'Company calendar',
                    url: 'company-calendar',
                    icon: null
                },
                {
                    title: 'Pending requests',
                    url: 'pending-requests',
                    icon: null
                }
            ]
        },
        {
            title: 'Events',
            url: '',
            icon: 'bookmarks',
            hidden: true
        },
        {
            title: 'MVP Files',
            url: '',
            icon: 'document',
            hidden: true
        },
        {
            title: 'Academy',
            url: '',
            icon: 'school',
            hidden: true
        },
        {
            title: 'Jobs',
            url: '/jobs',
            icon: 'briefcase',
            hidden: false
        },
        {
            title: 'Profile',
            url: '/profile',
            icon: 'person',
            hidden: false
        }
    ];
    public showHolidatorSubmenu=false;
    public arrow = 'chevron-down-outline';
    public displaySubmenu = 'display: none;';
    public displayAcordion= 'height: 0px';

    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private router: Router,
        private authService: AuthService,
        private navCtrl: NavController
    ) {
        this.initializeApp();
    }


    initializeApp() {
        this.platform.ready().then(() => {
            // this.statusBar.styleDefault();
            // this.splashScreen.hide();
        });

        this.authService.request('GET', 'v1/update', {}).subscribe((serverVersion: HttpResponse<any>) => {
            if (serverVersion.ok === true) {
                Device.getInfo().then((deviceInfo) => {
                    const platform = deviceInfo.platform;

                    if (platform !== 'android' && platform !== 'ios') {
                        return;
                    }
                    const version = deviceInfo.appVersion.split('.');

                    if (version.length !== 3) {
                        return;
                    }

                    if (serverVersion.body[platform+'_version'] !== '' && serverVersion.body[platform+'_version'] !== null) {
                        const platformServerVersion = serverVersion.body[platform+'_version'].split('.');

                        if (platformServerVersion.length !== 3) {
                            return;
                        }

                        if (platformServerVersion[0] > version[0]) {
                            // must update
                            const url = serverVersion.body[platform+'_update_url'];
                            return this.navCtrl.navigateRoot('/update/'+platform+'/'+encodeURIComponent(url));
                        }
                    }
                });
            }
        })
    }

    closeSession() {
        this.authService.logout();
    }

    toggleSubmenu(){
        if(this.displaySubmenu === 'display: none;'){
            this.displayAcordion = 'height: 50px; transition: 0.5s;';
            this.showHolidatorSubmenu = true;
            this.displaySubmenu = 'display: block;';
            this.arrow = 'chevron-up-outline';
        } else {
            this.displayAcordion = 'height: 0px; transition: 0.5s;';
            this.displaySubmenu = 'display: none;';
            this.showHolidatorSubmenu = false;
            this.arrow = 'chevron-down-outline';
        }
    }
}
